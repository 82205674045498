import React from 'react';
import { makeStyles, Typography, Grid, Theme, TextField, Button, Link } from '@material-ui/core';
import { Steps } from '../PreJoinScreens';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '0em',
    marginTop: '2em',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '2em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButton: {
    width: '100%',
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="center">
        <Grid item md={12} sm={12} xs={12} style={{ padding: '10px' }}>
          <Typography variant="h4" className={classes.gutterBottom}>
            Yorumunuz için tekrar teşekkürler.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
