import React from 'react';
import { makeStyles, Typography, Grid, Theme } from '@material-ui/core';
import { Steps } from '../PreJoinScreens';
import feedbackSad from '../../../images/feedback-sad.png';
import feedbackBad from '../../../images/feedback-bad.png';
import feedbackGood from '../../../images/feedback-good.png';
import feedbackBest from '../../../images/feedback-best.png';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButton: {
    width: '100%',
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const endpoint = process.env.REACT_APP_DEALERCOM_VCSI_ENDPOINT || 'http://localhost:3001';

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Görüşmenizi bizim için değerlendirir misiniz?
      </Typography>

      <Grid container justify="center">
        <Grid item md={3} sm={3} xs={3} style={{ padding: '10px' }}>
          <a href={'/' + roomName + '?thankyou&f=0'}>
            <img src={feedbackSad} width="100%" alt="Sad"></img>
          </a>
        </Grid>
        <Grid item md={3} sm={3} xs={3} style={{ padding: '10px' }}>
          <a href={'/' + roomName + '?thankyou&f=1'} target="_self">
            <img src={feedbackBad} width="100%" alt="Bad"></img>
          </a>
        </Grid>
        <Grid item md={3} sm={3} xs={3} style={{ padding: '10px' }}>
          <a href={'/' + roomName + '?thankyou&f=2'} target="_self">
            <img src={feedbackGood} width="100%" alt="Good"></img>
          </a>
        </Grid>
        <Grid item md={3} sm={3} xs={3} style={{ padding: '10px' }}>
          <a href={'/' + roomName + '?thankyou&f=3'} target="_self">
            <img src={feedbackBest} width="100%" alt="Best"></img>
          </a>
        </Grid>
      </Grid>
    </>
  );
}
